require('./bootstrap');
require('./bwpublisher');

let $partnerSlider = $('.partner-logos'),
	partnerSettings = {
	slidesToShow: 2,
	slidesToScroll: 2,
	arrows: false,
	dots: false,
	mobileFirst: true,
	responsive: [{
		breakpoint: 499,
		settings: {
			slidesToShow: 3,
			slidesToScroll: 3
		}
	}, {
		breakpoint: 639,
		settings: {
			slidesToShow: 4,
			slidesToScroll: 4
		}
	}, {
		breakpoint: 779,
		settings: {
			slidesToShow: 5,
			slidesToScroll: 5
		}
	}]
};

$(window).on('load', function () {
	if ($(window).width() <= 919) {
		$partnerSlider.not('.slick-initialized').slick(partnerSettings);
	}
});

$(window).on('resize', function () {
	$('.water-drop, .leaf').each(function () {
		calculateFlourishPosition($(this));
	});

	if ($(window).width() <= 919) {
		$partnerSlider.not('.slick-initialized').slick(partnerSettings);
	} else {
		$('.partner-logos.slick-initialized').slick('unslick');
	}
});

$(function () {
	// Overlay

	$('.overlay, [data-action="close-modal"]').on('click', function () {
		$('.overlay.active, .modal.active').removeClass('active');
	});

	// Mobile Nav

	$('.toggle-nav').on('click', function () {
		$('button.toggle-nav, header nav, .nav-overlay').toggleClass('active');
	});

	$('header nav button').on('click', function () {
		$(this).toggleClass('active').siblings('.container, ul').slideToggle(250);
	});

	$('nav [href="#"]').on('click', function (e) {
		e.preventDefault();
	});

	// Tables

	$('table').each(function () {
		$(this).wrap('<div class="responsive-table"></div>');
	});

	// Rotating element
	$('.water-drop, .leaf').each(function () {
		calculateFlourishPosition($(this));
	});

	// Hero Slider
	$('.page-slider').slick({
		dots: true,
		arrows: false
	});

	// Testimonials
	$('#testimonials .testimonials').slick({
		dots: false,
		arrows: true,
		nextArrow: $('#testimonials .next'),
		prevArrow: $('#testimonials .prev')
	});

	// Variant Toggle
	$('.variant-toggle button').on('click', function () {
		// Toggle Variant data once confirmed what should change
	});

	// Product Images Slider
	$('.product-images-slider').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		dots: true,
		cssEase: 'linear',
		appendDots: '.product-images'
	});

	$('.variant-toggle button').on('click', function () {
		let $tar = $(this).data('target'),
			$slider = $('.product-images-slider');

		$('.variant-toggle button').removeClass('active');
		$(this).addClass('active');
		$slider.slick('slickUnfilter');
		$slider.slick('slickFilter', '.product-image[data-variant="' + $tar + '"]');
	});

	$('.variant-toggle button.active').trigger('click');
});

function calculateFlourishPosition($this) {
	let $parent = $this.closest('.section-image-container'),
		$pos = ($this.outerHeight() / 2) + ($parent.outerHeight() / 2) + 40;

	$this.css({
		'top': -($this.outerHeight() / 2 + 40),
		'left': ($parent.outerWidth() / 2) - ($this.outerWidth() / 2),
		'transform-origin': '50% ' + $pos + 'px'
	});
}
