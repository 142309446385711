// AJAX

$(function () {
	$.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		}
	});
});


// Messages

$(function () {
	setTimeout(function () {
		$('.message.positive').animate({
			height: 'toggle',
			opacity: 'toggle'
		}, 500);
	}, 5000);
});


// Notifications

$(function () {
	if ($('.notification.active').length > 0) {
		$('.notification.active').find('.notification-content').slideToggle(250);
	}

	$('.notification').on('click', function () {
		$(this).toggleClass('active').find('.notification-content').slideToggle(250);
	});
});


// Galleries

const { default: lightGallery } = require('lightgallery');

$(function () {
	var galleries = document.querySelectorAll('.content-block .gallery');

	for (let gallery of galleries) {
		lightGallery(gallery, {
			licenseKey: process.env.MIX_LIGHT_GALLERY,
			selector: 'figure',
			subHtmlSelectorRelative: true,
			share: false
		});
	}
});


// Accordions

$(function () {
	if ($('.expandable .expandable-title.active').length > 0) {
		$('.expandable .expandable-title.active').next('.expandable-content').slideToggle(250);
	}

	$('.expandable .expandable-title').on('click', function () {
		$(this).toggleClass('active').next('.expandable-content').slideToggle(250);
	});
});


// Sliders

$(function () {
	var slideWrapper = $('.main-slider'),
		iframes = slideWrapper.find('.embed-player'),
		lazyImages = slideWrapper.find('.slide-image'),
		lazyCounter = 0;

	slideWrapper.on('init', function (slick) {
		slick = $(slick.currentTarget);

		setTimeout(function () {
			playPauseVideo(slick, 'play');
		}, 1000);

		resizePlayer(iframes, 16 / 9);
	});

	slideWrapper.on('beforeChange', function (event, slick) {
		slick = $(slick.$slider);

		playPauseVideo(slick, 'pause');
	});

	slideWrapper.on('afterChange', function (event, slick) {
		slick = $(slick.$slider);

		playPauseVideo(slick, 'play');
	});

	slideWrapper.on('lazyLoaded', function (event, slick, image, imageSource) {
		lazyCounter++;

		if (lazyCounter === lazyImages.length) {
			lazyImages.addClass('show');
		}
	});

	slideWrapper.slick({
		fade: true,
		autoplaySpeed: 4000,
		autoplay: true,
		lazyLoad: 'progressive',
		speed: 1000,
		arrows: false,
		dots: true,
		cssEase: 'cubic-bezier(0.87, 0.03, 0.41, 0.9)'
	});

	$('.default-slider').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		dots: true,
		fade: true,
		cssEase: 'linear',
		autoplay: true,
		autoplaySpeed: 4000
	});

	$(window).on('resize.slickVideoPlayer', function () {
		resizePlayer(iframes, 16 / 9);
	});
});

function resizePlayer(iframes, ratio) {
	if (!iframes[0]) return;

	var win = $('.main-slider'),
		width = win.width(),
		height = win.height(),
		playerWidth,
		playerHeight,
		ratio = ratio || 16 / 9;

	iframes.each(function () {
		var current = $(this);

		if (width / ratio < height) {
			playerWidth = Math.ceil(height * ratio);
			current.width(playerWidth).height(height).css({
				left: (width - playerWidth) / 2,
				top: 0
			});
		} else {
			playerHeight = Math.ceil(width / ratio);
			current.width(width).height(playerHeight).css({
				left: 0,
				top: (height - playerHeight) / 2
			});
		}
	});
}

function playPauseVideo(slick, control) {
	var currentSlide = slick.find('.slick-current'),
		slideType = currentSlide.attr('class').split(' ')[1],
		startTime = currentSlide.data('video-start'),
		player = currentSlide.find('iframe').get(0),
		video;

	if (slideType === 'vimeo') {
		switch (control) {
			case 'play':
				if ((startTime != null && startTime > 0) && !currentSlide.hasClass('started')) {
					currentSlide.addClass('started');
					postMessageToPlayer(player, {
						'method': 'setCurrentTime',
						'value': startTime
					});
				}

				postMessageToPlayer(player, {
					'method': 'play',
					'value': 1
				});
			break;
			case 'pause':
				postMessageToPlayer(player, {
					'method': 'pause',
					'value': 1
				});
			break;
		}
	} else if (slideType === 'youtube') {
		switch (control) {
			case 'play':
				postMessageToPlayer(player, {
					'event': 'command',
					'func': 'mute'
				});

				postMessageToPlayer(player, {
					'event': 'command',
					'func': 'playVideo'
				});
			break;
			case 'pause':
				postMessageToPlayer(player, {
					'event': 'command',
					'func': 'pauseVideo'
				});
			break;
		}
	} else if (slideType === 'video') {
		video = currentSlide.children('video').get(0);

		if (video != null) {
			if (control === 'play') {
				video.play();
			} else {
				video.pause();
			}
		}
	}
}

function postMessageToPlayer(player, command) {
	if (player == null || command == null) return;
	player.contentWindow.postMessage(JSON.stringify(command), '*');
}


// Tiles

$(function () {});


// Selectize

$(function () {
	$('select.selectize').each(function () {
		var create = false,
			delimiter = ',',
			persist = false,
			openOnFocus = true,
			sortField = 'value';

		if ($(this).hasClass('create')) {
			create = true;
		}

		if ($(this).hasClass('sort-index')) {
			sortField = [{
				field: 'index',
				direction: 'desc'
			}, {
				field: '$score'
			}];
		}

		if (create) {
			openOnFocus = false;

			var createUrl = $(this).data('create-url');
			create = function (input) {
				$.ajax({
					'type': 'post',
					'url': createUrl,
					'async': false,
					'data': {
						text: input,
					}
				}).done(function (response) {
					console.log(response);

					if (response.status === 201) {
						console.log(response);
						id = response.id;
					} else {
						console.log('ajaxerror', response);
					}
				}).fail(function (response) {
					console.log('ajaxerror', response);
				}).always(function (response) {
					console.log('ajaxalways', response);
				});

				return {
					value: id,
					text: input
				};
			}
		}

		$(this).selectize({
			delimiter: delimiter,
			persist: persist,
			create: create,
			sortField: sortField,
			openOnFocus: openOnFocus
		});
	});
});


// Date Picker (Flatpickr)

window.initializeFlatPickr = function () {
	// https://github.com/flatpickr/flatpickr
	$('input.flatpickr').each(function () {
		let mode = $(this).data('mode') || 'single',
			enableTime = $(this).data('enable-time') || false,
			noCalendar = $(this).data('no-calendar') || false,
			dateFormat = $(this).data('date-format') || (enableTime ? 'Y-m-d H:i:S' : 'Y-m-d'),
			altInput = $(this).data('alt-input') || true,
			altFormat = $(this).data('alt-format') || (enableTime ? 'd/m/Y H:i:S' : 'd/m/Y'),
			monthSelectorType = $(this).data('month-selector-type') || 'static',
			yearSelectorType = $(this).data('year-selector-type') || 'static',
			minDate = $(this).data('min-date') || null,
			maxDate = $(this).data('max-date') || null,
			defaultDate = $(this).data('default-date') || null;

		flatpickr($(this)[0], {
			mode: mode,
			monthSelectorType: monthSelectorType,
			yearSelectorType: yearSelectorType,
			enableTime: enableTime,
			noCalendar: noCalendar,
			dateFormat: dateFormat,
			altInput: altInput,
			altFormat: altFormat,
			minDate: minDate,
			maxDate: maxDate,
			defaultDate: defaultDate
		})
	});
};

initializeFlatPickr();
